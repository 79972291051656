
import React, { useState, useMemo, useRef, useCallback } from 'react';

import CaseMessageList from './case-message-list';
import CaseMessageDetail from "./case-message-detail"
import { Col, PageHeader, Row } from 'antd';
import "./case-message.css";
const CaseMessageConsole: React.FC = () => {
  
  return (
    <div>
      <PageHeader title="FTE Case Messages Console" />
      <Row>
        <Col span={6}><CaseMessageList /></Col>
        <Col span={18}><CaseMessageDetail /></Col>
      </Row>
    </div>

  )

}

export default CaseMessageConsole;