
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { Image } from 'antd';
import { Card, Spin } from 'antd';
import "./case-message-detail.css";
import { getMessageList } from '../../../../../utilities/CaseMessageUtility'
import MessageCard from './message-card';
export interface pageProps {
  caseData?: any;
  title?: React.ReactNode;
  onChildClick?: () => void;
  onFinish?: any;
  className?: string;
  isFteUser?: boolean;
  viewOldVersion?: boolean,
  refresh?: any,
  setTotalMsg?: any
}

const CaseMessageView: React.FC<pageProps> = (pageProps) => {
  const [caseData, setCaseData] = useState(pageProps.caseData)
  const [refresh, setRefresh] = useState(pageProps.refresh)
  const [totalMsg, setTotalMsg] = useState(0)
  const [msgList, setMsgList] = useState([])
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMessageList(caseData);
  }, [pageProps.caseData, pageProps.refresh]);

  let msgComponent: any = [];
  const fetchMessageList = (caseData: any) => {
    setLoading(true)
    getMessageList(caseData.caseNumber).then((data: any) => {
      setLoading(false)
      setMsgList(data.data.messages);
      if(data.data && data.data.messages && data.data.messages.length){
        pageProps.setTotalMsg(data.data.messages.length);
      } else {        
        pageProps.setTotalMsg(0);
      }
    })
  }
  
    msgList?.map((msg: any) => {
      msgComponent.push(
        <MessageCard msgData={msg} />
      )
    }) 
  
  return (
    <Card className="msg-view-card">
      <Spin spinning={loading} tip="Loading..."></Spin>
      {msgComponent}
    </Card>
  )

}

export default CaseMessageView;