/**
 * To communicate through events
 */
export const EventEmitter = {
  events: {},
  dispatch: function (event, data = null) {
    try {
      // Check if the specified event is exist / subscribed by anyone
      if (!this.events[event]) {
        // Doesn't exist, so just return
        return;
      } else {
        // Exists
        // Process all bound callbacks
        this.events[event].forEach((callback) => callback(data));

        // reset event
        // this.events[event] = [];
      }
    } catch (error) {
      console.log({ error });
    }
  },
  subscribe: function (event, callback) {
    try {
      // Check if the specified event is exist / subscribed by anyone
      if (!this.events[event]) {
        // Not subscribed yet, so make it an array so that further callbacks can be pushed
        this.events[event] = [];
      }
      // Push the current callback
      this.events[event].push(callback);
    } catch (error) {
      console.log({ error });
    }
  },
  unsubscribe: function (event) {
    try {
      // reset event
      this.events[event] = [];
    } catch (error) {
      console.log({ error });
    }
  },
};
