import React from "react";
import { Layout, Space } from "antd";
import "./AppHeader.css";
import "antd/dist/antd.css";
import logo from "../../../../assets/images/serviceo-logo-White.svg";
// import HeaderSearch from "./headerSearch/index";
import HeaderAvatar from "./headerAvatar/index";
import HeaderAppStore from "./headerAppStore/index";
import HeaderMenu from "./headerMenu/index";
const { Header } = Layout;

const AppHeader: React.FC = () => {
  return (

    <div key="appHeader">
      <Header className="header app-header">
        <img className="app-logo" width={200} height={50} src={logo} alt={logo}/>
        <Space className="right">
          {/* <HeaderSearch />
           */}
           <HeaderMenu />
          <HeaderAvatar />
          {/* <HeaderAppStore /> */}
        </Space>
      </Header>
    </div>
  );
};

export default AppHeader;
