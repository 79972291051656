
import React, { useState, useEffect } from 'react';
import {Col, Row, Typography, Button } from 'antd';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import { Card } from 'antd';
import "./case-message-detail.css";

const { Text } = Typography;
export interface pageProps {
  caseData?: any;
  title?: React.ReactNode;
  onChildClick?: () => void;
  onFinish?: any;
  className?: string;
  isFteUser?: boolean;
  viewOldVersion?: boolean,
  msgCount?: number
}

const CaseMessageHeader: React.FC<pageProps> = (pageProps) => {
  const [caseData, setCaseData] = useState(pageProps.caseData)
  const [msgCount, setMsgCount] = useState(pageProps.msgCount)
  const [toggle, setToggle] = useState(false);

  
  useEffect(() => {
    setMsgCount(pageProps.msgCount);
  }, [pageProps.msgCount]);

  function handleToggle () {
    setToggle(!toggle);    
  }

  return (
    <React.Fragment>
      <Card className="msg-header">
      <Row>          
          {/* <Col span={12}><Text strong>Message @ {caseData.caseNumber}</Text></Col> */}
          <Col span={12}><Text strong>PMS Case Number: {caseData.caseNumber} (Total Messages: {msgCount})</Text></Col>          
          <Col span={12}><Text strong>Jobsite: </Text> {caseData?.Jobsite?.Name}</Col>
        </Row>

        <Row>          
          {/* <Col span={12}><Text strong>Message @ {caseData.caseNumber}</Text></Col> */}
          <Col span={12}><Text strong>Assigned Worker: {caseData.AssignedWorker}</Text></Col>   
          <Col span={12}><Text strong>Partner Reference (Case): </Text> {caseData.PartnerCaseNumber}</Col>                 
        </Row>
        
        <div className={toggle ? 'showBox' : 'hideBox'}>
          <Row>
            <Col span={12}><Text strong>Account: </Text>{caseData?.Partner?.Name}</Col>
            <Col span={12}><Text strong>Program: </Text>{caseData?.Program?.Name}</Col>
          </Row>
          <Row>
            {/* <Col span={12}><Text strong>Jobsite: </Text> {caseData?.Jobsite?.Name}</Col> */}            
            <Col span={12}><Text strong>Priority: </Text> {caseData?.Priority}</Col>  
          </Row>
          <Row className='caseStatus'>
            <Col span={24}><Text strong>PMS Case Status: </Text> <span className='status'>{caseData?.Case?.Status}</span></Col>
          </Row>          
        </div>

        <Button className='toggleBoxBtn' type="primary"  shape="round" icon={toggle ? <CaretUpOutlined /> : <CaretDownOutlined />} onClick={handleToggle}></Button>
        
      </Card>

      

    </React.Fragment>
  )

}

export default CaseMessageHeader;