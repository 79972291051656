import React, {useEffect} from "react";
import { Avatar, Space, Dropdown, Menu } from "antd";
import { useAuth0 } from '@auth0/auth0-react';
import {
  UserOutlined,
  SettingOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import "./index.css";
import { EventEmitter } from "../../../../../helpers/EventEmitter";

const HeaderAvatar: React.FC = () => {
  const { user, logout, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const { name, picture, email } = user || {name: '', picture: 'https://joeschmoe.io/api/v1/random', email: ''};

  const setToken = async () => {
    const token = await getAccessTokenSilently();
    localStorage.setItem('accessToken', token);
    const claims = await getIdTokenClaims();
    const id_token = claims?.__raw || '';
    localStorage.setItem('idToken', id_token);
  }

  const _logout = () => {
    logout({ returnTo: window.location.origin });
  }
    
  useEffect(() => {
    setToken();

    // Subscribe to 401 Unauthorized event and trigger logout when it occurs
    EventEmitter.subscribe("unauthorizedAccess_401", _logout);

    return () => {
      // Unsubscribe from the 401 Unauthorized event to stop triggering the logout handler
      EventEmitter.unsubscribe("unauthorizedAccess_401");
    };
  }, []);

  const menu = (
    <Menu
      className="avtMenuwidth"
      items={[
        {
          key: "1",
          icon: <Avatar src={picture} size={40} />,
          label: (
            <a rel="noopener noreferrer">
               {name} <br />
              <small className="lowerCase">{email}</small> 
            </a>
          ),
        },
        {
          key: "2",
          icon: <UserOutlined />,
          disabled: true,
          label: <a>My Profile</a>,
        },
        {
          key: "3",
          icon: <SettingOutlined />,
          disabled: true,
          label: <a>Setting</a>,
        },
        {
          key: "4",
          danger: true,
          icon: <PoweroffOutlined />,
          label: <a onClick={(e) => logout({ returnTo: window.location.origin })}>Logout</a>,
        },
      ]}
    />
  );

  return (
    <Space wrap>
      <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            <Avatar
              src={picture}
              size={35}
              style={{ border: "2px solid #ffff", margin: "2rem 1.5rem 2rem 0" }}
            />
          </Space>
        </a>
      </Dropdown>
    </Space>
  );
};

export default HeaderAvatar;
