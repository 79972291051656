// src/auth/auth0-provider-with-history.js

import React, { ReactNode } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import  environment from '../../../environments/environment';
interface IProps {
  children?: ReactNode
}


const Auth0ProviderWithNavigate = ({ children }: IProps) => {
  const AUTH0_DOMAIN = environment.auth.CLIENT_DOMAIN || 'serviceo-staging.auth0.com'; 
  const AUTH0_CLIENT_ID = environment.auth.CLIENT_ID || 'S9YWp1kBcMqo0qC8beKCMH7403KYspDE';
  const AUTH0_AUDIENCE = environment.auth.AUDIENCE || 'https://den.staging.serviceo.me/api/v1/';

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      cacheLocation={"localstorage"}
      audience= {AUTH0_AUDIENCE}
      scope= "read:current_user update:current_user_metadata"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;
