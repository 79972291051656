import React, { useCallback } from "react";
import type { MenuProps } from "antd";
import { Layout, Menu} from "antd";
import "./Sidebar.css";
import {
  FileAddOutlined,
  BellOutlined,
  UnorderedListOutlined,
  TeamOutlined,
} from "@ant-design/icons";

import "./Sidebar.css";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
const { Sider } = Layout;
const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const siderStyle: React.CSSProperties = {
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100vh - 64px)",
    position: "inherit",
    left: 0,
    top: 0,
    bottom: 0,
  };

  const onMenuClick = useCallback((event: any) => {
    let { key } = event;
    navigate(`${key}`, { replace: true });
  }, []);
  const userInfo:any = user;
  const userMetaData = JSON.parse(userInfo['https://den.serviceo.me/app_metadata']);
  const menujson = [  
    {
      icon: UnorderedListOutlined, 
      label: "Service Manager",
      key: "list-fte-case",
    }
    // {
    //   icon: CommentOutlined,
    //   label: "Message",
    //   key: "message",
    //   disabled: true
    // }
  ];
  const accessType = userMetaData['accessType'] ? userMetaData['accessType'].toLowerCase() : '';
  const isAdmin = accessType !== 'vendor' ? true : false;
  if(isAdmin){
    menujson.splice(2, 0,
      {
        icon: FileAddOutlined,
        label: "Create FTE Case",
        key: "create-fte-case",
      }, 
      {
        icon: TeamOutlined,
        label: "Team Setup",
        key: "fte-team",
      }
    )
  }

  menujson.push({
    icon: BellOutlined,
    label: "Activity Feed",
    key: "notifications",
  })
  
  const items2: MenuProps["items"] = menujson.map((item, index) => {
    return {
      key: item.key,
      disabled: false,
      label: (
        <span>
          {React.createElement(item.icon)} <br />
          <small title={item.label}>{item.label}</small>
        </span>
      ),
    };
  });
  return (
    <Sider width={70} className="site-layout-background" style={siderStyle}>
      <Menu
        mode="vertical"
        onClick={onMenuClick}
        defaultSelectedKeys={[""]}
        style={{ height: "100%", borderRight: 0 }}
        items={items2}
      />
    </Sider>
  );
}


export default Sidebar;