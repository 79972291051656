
import React, { useState } from 'react';
import { Card, Image } from 'antd';
import { FileTextFilled, ArrowDownOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useAuth0 } from "@auth0/auth0-react";
import "./case-message-detail.css";
import moment from 'moment'
import ReactPlayer from 'react-player';

export interface msgProps {
    msgData?: any;
}
const MessageCard: React.FC<msgProps> = (msgProps) => {

    const { user } = useAuth0();
    const userInfo: any = user;

    const [message, setMessage] = useState(msgProps.msgData)
    const currentUsersfdcId = userInfo['https://den.serviceo.me/'];
    const messageClass = message && message.sender && message.sender.sfdcId == currentUsersfdcId ? 'msgContent rightSide' : 'msgContent leftSide';
    // const nameClass = message.sender.sfdcId == currentUsersfdcId ? 'PerName text-left' : 'PerName text-left';    
    const newClass = message && message.sender && message.sender.firstName == 'Auto' ? 'caseGray' : '';




    
    // { overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', webkitLineClamp: '3', lineClamp: 3, webkitBoxOrient: 'vertical'}    

    const [toggleBtn, setToggleBtn] = useState(false)

    const isOpenCss = !toggleBtn && 'isOpenCss';
    
    const charMax = 580
    // let messageData = message?.message.length > charMax ? message?.message.slice(0, charMax) + `...` : message?.message

    function handleReadMore () {
        setToggleBtn(!toggleBtn);        
    }

    return (
        <Card className='msgMain'>
            <div className={messageClass + ' ' + newClass}>
                <p className='PerName'>{message?.sender?.firstName} {message?.sender?.lastName}</p>

                {message?.document && message?.document?.extension && (message?.document?.extension.toLowerCase() == 'jpg' || message?.document?.extension.toLowerCase() == 'jpeg' || message?.document?.extension.toLowerCase() == 'gif' || message?.document?.extension.toLowerCase() == 'png' || message?.document?.extension.toLowerCase() == 'tiff' || message?.document?.extension.toLowerCase() == 'avif' || message?.document?.extension.toLowerCase() == 'jfif') &&
                    <p className='msg'> <Image src={message?.message} /></p>}
                {!message?.isFile &&
                    <div>
                        <p className={`msg ${isOpenCss}`}>{message?.message}</p>
                        {(message?.message.length > charMax) && <button className='readMoreBtn' onClick={handleReadMore}>{!toggleBtn ? 'Read More' : 'Show Less'}</button>}
                    </div>
                }                 

                {message?.document && message?.document?.extension && (message?.document?.extension.toLowerCase() == 'pdf' || message?.document?.extension.toLowerCase() == 'xlsx' || message?.document?.extension.toLowerCase() == 'doc' || message?.document?.extension.toLowerCase() == 'docx' || message?.document?.extension.toLowerCase() == 'xls' || message?.document?.extension.toLowerCase() == 'htm' || message?.document?.extension.toLowerCase() == 'html' || message?.document?.extension.toLowerCase() == 'odt' || message?.document?.extension.toLowerCase() == 'ods' || message?.document?.extension.toLowerCase() == 'csv' || message?.document?.extension.toLowerCase() == 'ppt' || message?.document?.extension.toLowerCase() == 'mpg' || message?.document?.extension.toLowerCase() == 'mpeg' || message?.document?.extension.toLowerCase() == 'txt') &&
                    <p className='msg'> <a className='msgDocLink' href={message?.document?.url}  ><FileTextFilled /> <ArrowDownOutlined /> {message?.document?.name}.{message?.document?.extension} </a></p>
                }

                {message?.document && message?.document?.extension && (message?.document?.extension.toLowerCase() == 'mp4' || message?.document?.extension.toLowerCase() == 'webm' || message?.document?.extension.toLowerCase() == 'ogg' || message?.document?.extension.toLowerCase() == 'avi' || message?.document?.extension.toLowerCase() == 'mov' || message?.document?.extension.toLowerCase() == 'flv' || message?.document?.extension.toLowerCase() == 'm4v') &&
                    <p className='msg'> <ReactPlayer  controls = {true} url={message?.document?.url}/></p>}

                <span className="sentTime">{moment(message?.createdAt).format('MMMM D, YYYY, h:mm a z')}</span>
            </div>
        </Card>
    )

}

export default MessageCard;