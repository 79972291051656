const environment = {
    envName: process.env.REACT_APP_ENV_NAME,
    production: false,
    baseUrl: process.env.REACT_APP_BASE_URL,
    serviceoBaseurl: process.env.REACT_APP_SERVICEO_BASE_URL,
    apiVersion: process.env.REACT_APP_API_VERSION,
    auth: {
        App_Name: process.env.REACT_APP_AUTH_NAME || '',
        CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID || '',
        CLIENT_DOMAIN: process.env.REACT_APP_AUTH_CLIENT_DOMAIN || '',
        REDIRECT: process.env.REACT_APP_AUTH_REDIRECT_URL || window.location.origin,
        LOGOUT_URL: process.env.REACT_APP_AUTH_REDIRECT_URL|| '',
        CACHE_LOCATION: 'localstorage',
        USE_REFRESH_TOKENS: true,
        AUDIENCE: process.env.REACT_APP_AUTH_AUDIENCE
    },
    jdcAccess: process.env.REACT_APP_AUTH_ADMIN_ACCESS_ROLES,
    talentMasterUrl: process.env.REACT_APP_TALENT_MASTER_URL || '',
    storageUrl: process.env.REACT_APP_STORAGE_URL || '',
    messengerApiUrl: process.env.REACT_APP_MESSENGER_URL,
}

export default environment;