import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { Layout } from "antd";
import ProtectedRoute from "./ProtectedRoute";
import CaseMessageConsole from "../pages/case-message/case-message-console";

const PrivateRoute: React.FC = () => {

  const FteCaseList = lazy(() => import("../pages/fte-case-list/fte-case-list"));
  const TeamGroupList = lazy(() => import("../pages/team-group-list/team-group-list"));
  const NotificaionsList = lazy(() => import("../pages/notifications/notificaitons-list"));
  const FteCaseCreate = lazy(() => import("../pages/fte-case-create/fte-case-create"));

  const layoutStyle: React.CSSProperties = {
    padding: "0 24px 24px",
    backgroundColor: '#fff'
  };

  return (
    <Layout style={layoutStyle}>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<ProtectedRoute component={FteCaseList} />} />
          <Route path="/list-fte-case"  element={<ProtectedRoute component={FteCaseList} />}/>  
          <Route path="/fte-team"  element={<ProtectedRoute component={TeamGroupList} />}/>
          <Route path="/notifications"  element={<ProtectedRoute component={NotificaionsList} />}/>
          <Route path="/create-fte-case"  element={<ProtectedRoute component={FteCaseCreate} />}/>  
          <Route path="/message"  element={<ProtectedRoute component={CaseMessageConsole} />}/>

        </Routes>
      </Suspense>
    </Layout>
  );
};


export default PrivateRoute;