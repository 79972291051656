
import React, { useState, useMemo, useRef, useCallback } from 'react';

import CaseMessageHeader from "./case-message-detail/case-message-header"
import CaseMessageView from './case-message-detail/case-message-view';
import CaseMessagePost from './case-message-detail/case-message-post';
import { Col, PageHeader, Row } from 'antd';
import "./case-message.css";

export interface pageProps {
  caseData?: any;
  title?: React.ReactNode;
  onChildClick?: () => void;
  onFinish?: any;
  className?: string;
  isFteUser?:boolean;
  viewOldVersion?: boolean
}


const CaseMessageDetail: React.FC<pageProps> = (pageProps) => {
  const [caseData, setCaseData] = useState(pageProps.caseData);
  const [refreshData, setRefreshData] = useState('ref');
  const [totalMsg, setTotalMsg] = useState(0)

  const onUpdateFinish = async (id: string) => {
    setRefreshData(id)
  }

  const updateTotalMsg = async (total: number) => {
    setTotalMsg(total)
  }

  return (
    <div className='messageMainContainer'>
      <div className='headBodyWrap'>
      <Row className='headerMain'>
        <Col span={24}><CaseMessageHeader caseData = {caseData} msgCount = {totalMsg} /></Col>
      </Row>
      <Row>
        <Col span={24}><CaseMessageView caseData = {caseData}  setTotalMsg={updateTotalMsg} refresh = {refreshData} /></Col>
      </Row>
      </div>
      <Row className='msgFooter'>
        <Col span={24}><CaseMessagePost caseData = {caseData} onFinish={onUpdateFinish} /></Col>
      </Row>
    </div>

  )

}

export default CaseMessageDetail;