
import React, { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import "./case-message.css";
import { Card } from 'antd';
import { Col, Row } from 'antd';
import { getCaseList } from '../../../../utilities/CaseMessageUtility'
const CaseMessageList: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [selectedCase, setselectedCase] = useState('');

  useEffect(() => {    
    fetchListing(20, 1);
  });

  const fetchListing = (perPage = 60, pageNo = 1, filter: any = {}) => {
    setLoading(true);
    let listData: any = [];
    // getCaseList(perPage, pageNo, filter).then((data: any) => {
    //   let jdData = data[0].data;
    //   console.log(jdData);
    //   setLoading(false);
    //   jdData.forEach((item:any) => {
    //     console.log(item);
    //     listData.push(item);
    //   })
    //   // setselectedCase()
    //   console.log(listData)
    // })
  }

  

  return (
    <Card className="msg-list-card">
      <div className="msg-list-container">
        <Row>
          <Col span={22}>Messages (3)</Col>
          <Col span={2}><span className="time-right">11:00</span></Col>
          <Col span={24}>est to check the PPE hours under the appointment tab</Col>
          <Col span={12}>Magiclink (American Bank) Fiel</Col>
          <Col span={12}>Fremont - USA -SG001705- IJS02216-SFO099 TEST</Col>
        </Row>
      </div>
      <div className="msg-list-container">
        <Row>
          <Col span={22}>Messages (3)</Col>
          <Col span={2}><span className="time-right">11:00</span></Col>
          <Col span={24}>est to check the PPE hours under the appointment tab</Col>
          <Col span={12}>Magiclink (American Bank) Fiel</Col>
          <Col span={12}>Fremont - USA -SG001705- IJS02216-SFO099 TEST</Col>
        </Row>
      </div>
      <div className="msg-list-container">
        <Row>
          <Col span={22}>Messages (3)</Col>
          <Col span={2}><span className="time-right">11:00</span></Col>
          <Col span={24}>est to check the PPE hours under the appointment tab</Col>
          <Col span={12}>Magiclink (American Bank) Fiel</Col>
          <Col span={12}>Fremont - USA -SG001705- IJS02216-SFO099 TEST</Col>
        </Row>
      </div>
      <div className="msg-list-container">
        <Row>
          <Col span={22}>Messages (3)</Col>
          <Col span={2}><span className="time-right">11:00</span></Col>
          <Col span={24}>Case: 32391 est to check the PPE hours under the appointment tab</Col>
          <Col span={12}>Magiclink (American Bank) Fiel</Col>
          <Col span={12}>Fremont - USA -SG001705- IJS02216-SFO099 TEST</Col>
        </Row>
      </div>
      <div className="msg-list-container">
        <Row>
          <Col span={22}>Messages (3)</Col>
          <Col span={2}><span className="time-right">11:00</span></Col>
          <Col span={24}>Case: 32391 est to check the PPE hours under the appointment tab</Col>
          <Col span={12}>Magiclink (American Bank) Fiel</Col>
          <Col span={12}>Fremont - USA -SG001705- IJS02216-SFO099 TEST</Col>
        </Row>
      </div>
      <div className="msg-list-container">
        <Row>
          <Col span={22}>Messages (3)</Col>
          <Col span={2}><span className="time-right">11:00</span></Col>
          <Col span={24}>Case: 32391 est to check the PPE hours under the appointment tab</Col>
          <Col span={12}>Magiclink (American Bank) Fiel</Col>
          <Col span={12}>Fremont - USA -SG001705- IJS02216-SFO099 TEST</Col>
        </Row>
      </div>
      <div className="msg-list-container">
        <Row>
          <Col span={22}>Messages (3)</Col>
          <Col span={2}><span className="time-right">11:00</span></Col>
          <Col span={24}>Case: 32391 est to check the PPE hours under the appointment tab</Col>
          <Col span={12}>Magiclink (American Bank) Fiel</Col>
          <Col span={12}>Fremont - USA -SG001705- IJS02216-SFO099 TEST</Col>
        </Row>
      </div>
      <div className="msg-list-container">
        <Row>
          <Col span={22}>Messages (3)</Col>
          <Col span={2}><span className="time-right">11:00</span></Col>
          <Col span={24}>Case: 32391 est to check the PPE hours under the appointment tab</Col>
          <Col span={12}>Magiclink (American Bank) Fiel</Col>
          <Col span={12}>Fremont - USA -SG001705- IJS02216-SFO099 TEST</Col>
        </Row>
      </div>
    </Card>
  )

}

export default CaseMessageList;



