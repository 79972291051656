import  environment  from '../environments/environment';
const baseUrl = environment.baseUrl + '/api/v1';

export const getCaseList = (pageSize: Number = 20, paneNo: Number = 1, filter:any={}): Promise<any> => {
    const apiUrl = `${baseUrl}/messages/get-case-list`
   console.log(apiUrl)
    const requestOptions:any = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: {}
    };
    //Object.assign(filter,{ $or: [ { isLatestJD: true }, { publishedStatus: 'DRAFT' } ] });
    // if(filter!==undefined && Object.keys(filter) && Object.keys(filter).length){
    //    requestOptions['body'] = JSON.stringify(filter);
    // }
    return fetch(apiUrl,requestOptions).then((resp) => resp.json())
    .then((data) => {
        return data;
    });
}

export const getMessageList = (caseNumber: string): Promise<any> => {
    const apiUrl = `${baseUrl}/messages/get-message-list/${caseNumber}`
    const requestOptions:any = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' }
    };
    return fetch(apiUrl,requestOptions).then((resp) => resp.json())
    .then((data) => {
        return data;
    });
}

export const postMessage = (caseNumber: string, msgBody: any): Promise<any> => {
    const apiUrl = `${baseUrl}/messages/post-message/${caseNumber}`
    const requestOptions:any = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(msgBody)
    };
    return fetch(apiUrl,requestOptions).then((resp) => resp.json())
    .then((data) => {
        return data;
    });
}