import fetchIntercept from "fetch-intercept";
import { EventEmitter } from "../../helpers/EventEmitter";

fetchIntercept.register({
    request: function(url, config) {
      // const token = url.includes("messages") ? localStorage.getItem('accessToken'): localStorage.getItem('accessToken');
      const token = localStorage.getItem('accessToken')
      if(config && config.headers && config && config.headers['Authorization']){
       // Nothing to do here
      } else if(config && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`
     } else if (config){
      config = {headers: {Authorization: `Bearer ${token}`}}
     } else {
      config = {headers: {Authorization: `Bearer ${token}`}}
     }
     if (url.indexOf('den') !== -1) {
      config.headers['Authorization'] = `Bearer ${token}`;
     }
    //  if(url.match('/api/v1/skill/list') || url.match('/api/v1/skill/count') || url.match('/api/v1/skill//cities')){
    //   delete config.headers['Authorization'];
    //  }
      return [url, config];
    },
  
    requestError: function(error) {
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error);
    },
  
    response: function(response) {
      if(response.status === 401){
        // Dispatch event for handling API 401 Unauthorized status
        EventEmitter.dispatch("unauthorizedAccess_401");
      }
      // Modify the response object
      return response;
    },
    
    responseError: function(error) {
      if(error && error.message){
        // Dispatch event for handling API 401 Unauthorized status
        EventEmitter.dispatch("unauthorizedAccess_401");
      }
      // Handle an fetch error
      return Promise.reject(error);
    }
  });