// import { IOlympicData } from '../utility.interface';
import environment from '../../environments/environment';
import "./fetch-intercept.service";
const baseUrl = environment.baseUrl + '/api/v1';
// const serviceoBaseUrl = environment.serviceoBaseurl;


export const removeMemberById = (teamMemberId: any): Promise<unknown> => {
    const aprUrl = `${baseUrl}/teams/remove-member/` + teamMemberId;
    return fetch(aprUrl, {
        method: "DELETE",
        headers: {
            'Content-type': 'application/json'
        }
    })
        .then((resp) => resp.json())
        .then((data: any) => {
            return data;
        });
}




export const updateFteCaseDetail = (cId: string, data: any,changelog:any): Promise<any> => {
    const apiUrl = `${baseUrl}/dedicated-case/` + cId;
    let postData: any = {data:data,changelog:changelog};

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data._id);

}



export const getDocumentList = (reqData: any): Promise<any> => {
    const apiUrl = `${baseUrl}/documents/documentlist`
    let postData: any = reqData;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data);

}

export const getTeamMemberList = (reqData: any): Promise<any> => {
    const apiUrl = `${baseUrl}/teams/team-member`
    let postData: any = reqData;

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data);

}


export const createFTECase = (data: any, files:any): Promise<any> => {
    const apiUrl = `${baseUrl}/dedicated-case/create-fte-case`;
   // let postData: any = data;
    let postData: any = {data:data,files:files};

    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => {
            if(!response.data){
                return null;
            }
           return response.data
        }
            );

}


export const createDocuments = (data: any): Promise<any> => {
    const apiUrl = `${baseUrl}/documents/create`;
    let postData: any = data;
    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => {
            console.log('response===>>',response)
            if(!response.data){
                return null;
            }
           return response.data._id
        }
            );

}

export const createTeamMembers = (data: any): Promise<any> => {
    const apiUrl = `${baseUrl}/teams/assign-team-member`;
    let postData: any = data;
    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => {
            console.log('response===>>',response)
            if(!response.data){
                return null;
            }
           return response.data._id
        }
            );

}

export const createTeam = (data: any): Promise<any> => {
    const apiUrl = `${baseUrl}/teams/create-team`;
    let postData: any = data;
    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => {
            if(!response.data){
                return null;
            }
           return response.data._id
        }
            );

}

export const uploadToSnow = (data: any): Promise<any> => {
    const apiUrl = `${baseUrl}/documents/uploadFileToSnow`;
    let postData: any = data;
    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => {
            if(!response.data){
                return null;
            }
           return response.data
        }
            );

}


export const getWorkers = (data:any): Promise<unknown> => {
    const apiUrl = `${baseUrl}/dedicated-case/get-worker-list`;
    let postData: any = data;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };

    return fetch(apiUrl, requestOptions)
        .then((resp) => resp.json())
        .then((data) => {
            let list: any = []
            Object.values(data.data).forEach((i: any) => {
                let w = { label: `${i.Name} (${i.Dispatch_Worker_num__c})`, value: i.sfdcId, Name: i.Name, Email__c : i.Email__c, Contact__c : i.Contact__c }
                list.push(w)
            })
            return {
                workerList: list
            }

            // return {
            //     workerList: Object.keys(data.data).map((obj: any,val) => ({ Name: obj.Name ? obj.Name :'', value: obj.sfdcId, title: obj.Name, label:obj.Name })),
            //    }
            // return {
            //     workerList: (data1).map((obj:any) => ({ Name: obj.Name ? obj.Name :'', value: obj.sfdcId, title: obj.Name, label:obj.Name })),
            //    }
        });
}

export const getUsers = (data:any): Promise<unknown> => {
    const apiUrl = `${baseUrl}/teams/get-user-list`;
    let postData: any = data;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };

    return fetch(apiUrl, requestOptions)
        .then((resp) => resp.json())
        .then((data) => {
             return {
                userList: (data.data).map((obj:any) => ({ Name: obj.workers[0] ? obj.workers[0].Name :'', value: obj.sfdcId, title: obj.workers[0] ? obj.workers[0].Name :'',
                label:obj.workers[0] ? obj.workers[0].Name :'', email: obj.email, workerSfdcId: obj.workers[0] ? obj.workers[0].sfdcId :'',workerEmail: obj.workers[0] ? obj.workers[0].Email__c :''})),
               }
        });
}

export const getJobsiteUsers = (data:any): Promise<unknown> => {
    const apiUrl = `${baseUrl}/teams/get-jobsite-user`;
    let postData: any = data;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };

    return fetch(apiUrl, requestOptions)
        .then((resp) => resp.json())
        .then((data) => {
             return {
                jobsiteUserList: (data.data).map((obj:any) => ({ Name: obj.Name ? obj.Name :'', value: obj.userSfdcId, title: obj.Name ? obj.Name :'',
                label:obj.Name ? obj.Name :'', email: obj.email, workerSfdcId: obj.workerSfdcId ? obj.workerSfdcId :'', TeamName : obj.TeamName ? obj.TeamName : ''})),
               }
        });
}

export const getTeamByJobsite = (data: any): Promise<unknown> => {
    const apiUrl = `${baseUrl}/teams/get-teambyjobsite`;
    let postData: any = data;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };

    return fetch(apiUrl, requestOptions)
        .then((resp) => resp.json())
        .then((data) => {
            return {
                TeamName: data && data.data && data.data.length ? data.data[0].TeamName : '',
            }
        });
}


export const getPartners = (): Promise<unknown> => {
    const aprUrl = `${baseUrl}/teams/get-partner`;
    return fetch(aprUrl)
        .then((resp) => resp.json())
        .then((data) => {
            return {
                partnerList: (data.data || []).map((obj: any,) => ({ sfdcId: obj.sfdcId ? obj.sfdcId :'', value: obj.sfdcId, title: obj.Name, label:obj.Name })),
            }
        });
}

export const getPrograms = (data:any): Promise<unknown> => {
    const aprUrl = `${baseUrl}/teams/get-program`;
    let postData: any = data;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };

    return fetch(aprUrl, requestOptions)
        .then((resp) => resp.json())
        .then((data) => {
            return {
                programList: (data.data.programs || []).map((obj: any,) => ({ sfdcId: obj.sfdcId ? obj.sfdcId :'', value: obj.sfdcId, title: obj.Name, label:obj.Name, Project__c : obj.Project__c })),
            }
        });
}

export const getJobsites = (data:any): Promise<unknown> => {
    const aprUrl = `${baseUrl}/teams/get-jobsite`;
    let postData: any = data;
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };

    return fetch(aprUrl, requestOptions)
        .then((resp) => resp.json())
        .then((data) => {
            return {
                jobsiteList: (data.data.data.jobsites || []).map((obj: any,) => ({ sfdcId: obj.sfdcId ? obj.sfdcId :'', value: obj.sfdcId, title: obj.Name, label:obj.Name, Jobsite: obj.Jobsite_ID__c })),
                jobsiteListObj:data.data.data.jobsites
            }
        });
}


export const updateJDDescriptionNote = (jdId: string, data: any): Promise<any> => {
    const apiUrl = `${baseUrl}/master/jd/` + jdId;
    let postData: any = {notes:data};
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData)
    };
    return fetch(apiUrl, requestOptions).then((resp) => resp.json())
        .then(response => response.data._id);

}

