  
import React, { useState, useMemo } from 'react';
import "./case-message-detail.css";
import { Form, Button, Input, Spin, Modal } from 'antd';
import { UploadOutlined, SendOutlined, PlusOutlined } from '@ant-design/icons';
import { postMessage } from './../../../../../utilities/CaseMessageUtility'
import { CustomeNotification } from '../../../../../utilities/NotificationUtility';
import FileUpload from "./../../file-upload/file-upload";


export interface pageProps {
  caseData?: any;
  title?: React.ReactNode;
  onChildClick?: () => void;
  onFinish?: any;
  className?: string;
  isFteUser?: boolean;
  viewOldVersion?: boolean
}
const CaseMessagePost: React.FC<pageProps> = (pageProps) => {
  const [caseData, setCaseData] = useState(pageProps.caseData)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm();
  const [upload, setUpload] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { TextArea } = Input;

  const uploadIconStyle = useMemo(() => ({
    fontSize: 25,
    color: '#1890ff',
    fontWeight: 'bold',
    marginRight: 4
  }), []);

  const rightOutlinedIconStyle = useMemo(() => ({
    fontSize: 16,
    color: '#ffff',
    fontWeight: 'bold'
  }), []);

  const uploadClick = () => {
    setUpload(true)
    setIsModalOpen(true);

    console.log('uploadClick>>>>')
  }
  const onFinish = (event: any) => {
    if (event.message.trim() === '') {
      form.setFieldsValue({ message: null });
      form.resetFields();
      form.validateFields()
      return
    }
    setLoading(true)
    const msgBody = {
      "message": event.message,
      "channelId": caseData?.channel?.channelId || null,
      "priority": "STANDARD",
      "documentId": null,
      "tagUsers": [],
      "source": "Team",
      "type": "SEND",
      "pCaseNumber": caseData.PartnerCaseNumber
    }
    postMessage(caseData.CaseNumber, msgBody).then(res => {
      if (res && res.success) {
        form.setFieldsValue({ message: null });
        form.resetFields();
        setLoading(false)
        const ref = 'Ref' + Date.now();
        pageProps.onFinish(ref);
        CustomeNotification('success', `Message Sent successfully `);
        // Need to refresh list section
      } else {
        setLoading(false)
        CustomeNotification('error', `Error in sending message !!!!`, res?.message);
      }
    })

  }

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const suffix = (
    <PlusOutlined title='upload' onClick={uploadClick} style={uploadIconStyle} />
  );

  const forwardIcon = (
    // <RightOutlined  style={rightOutlinedIconStyle} />
    <SendOutlined style={rightOutlinedIconStyle} />
  );

  const onUpdateFinish = async (values: any) => {
    setIsModalOpen(false);
    const ref = 'Ref' + Date.now();
    pageProps.onFinish(ref);
  }

  return (
    <div className='postMainWrapper'>
      <div className="post-msg-input">
        <Spin spinning={loading} tip="Loading..."></Spin>
        <Form layout="vertical" onFinish={onFinish} form={form} autoComplete="off">
          <div className='msgFooterWrap'>
            <Form.Item name="message"
              rules={[{
                required: true,
                message: 'Message could not be empty!'
              }]}>
              {/* <Input size="large" placeholder="Type Message Here" prefix={suffix} /> */}

              <div style={{ display: 'flex', alignItems: 'center' }}>

              {/*  <span>{suffix}</span> */}
                <TextArea
                  placeholder="Type Message Here"
                  autoSize={{
                    minRows: 1,
                    maxRows: 2,
                  }}
                />
              </div>
            </Form.Item>
            <Button type="primary" htmlType="submit"> Send {forwardIcon} </Button>
          </div>
        </Form>

      </div>

      <div>
        <Modal className='uploadPopUp' title='Upload Document' width={800} footer={[]} visible={isModalOpen} onCancel={handleCancel} >

          {upload && <FileUpload data={{"channelId": caseData?.channel?.channelId || null , "caseNumber" :caseData?.CaseNumber }}  onFinish={onUpdateFinish} />}
        </Modal>
      </div>

    </div>

  )

}

export default CaseMessagePost;