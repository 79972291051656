import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Image, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "./file-upload.css";
import type { UploadProps, ProgressProps } from 'antd';
import  environment  from '../../../../environments/environment';
import { uploadToSnow } from '../../../../utilities/http-services/job-description.service';




export interface pageProps {
  data?: any;
  onFinish?: any;
  title?: React.ReactNode
}
const { Dragger } = Upload;


const FileUpload: React.FC<pageProps> = (pageProps) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(pageProps.data)
  // const [caseNumber, setCaseNumber] = useState(pageProps.data);
  const caseNumber = pageProps.data

  useEffect(() => {
    if (pageProps.data && Object.keys(pageProps.data)) {
      setData(pageProps.data)

    }

  }, [pageProps.data]);


  const props: UploadProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    action: environment.messengerApiUrl +`/api/v1/chat/file?channelId=${data.channelId}&source=Team`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('accessToken')}`
    },
    maxCount: 1,
    progress: { strokeWidth: 2, showInfo: true },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        //   message.success(`${info.file.name} file uploaded successfully.`);
        pageProps.onFinish('test');
        if(info.file.response && info.file.response.data[0] && info.file.response.data[0].message){
          const dataUpload = {
            "caseNumber": data.caseNumber,
            "url": info.file.response.data[0].message,
            "name": info.file.name
        }
          uploadToSnow(dataUpload).then((res: any) => {
          console.log('success=====')
          });
        }

       // console.log('file response', info.file.response.data[0].message)
      } else if (status === 'error') {
        //  message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };


  const gridRef = useRef<AgGridReact<any>>(null);
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  return (
    <div style={containerStyle} key="AgGridSample">


      <Dragger {...props} >
        
        <p className="ant-upload-text">Drag & Drop your file here or <a href='#'>browse files</a></p>

        <div style={{marginTop: '5em'}}>
        <p className='allFiles'>We Accept all file format!</p>
          <div className='noteWrapper' style={{left: '54%'}}>
              <span>Only 1 File is allowed to upload at a time.</span>
              <span>Maximum size allowed for a file is 20 MB.</span>
          </div>
        </div>
        {/* <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibited from uploading company data or other
          banned files.
        </p> */}
      </Dragger>
      

    </div>

  )

}


export default FileUpload;
