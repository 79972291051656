import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { ComponentType, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

interface ProtectedRouteProps {
  component: ComponentType;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component }) => {
  const { getAccessTokenSilently } = useAuth0();
  const RedirecContent = <div>Redirecting you to the login page...</div>;
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => RedirecContent,
  });

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = process.env.SERVICEO_BASE_URL ;
  
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: domain +'/',//`https://${domain}/api/v1/`,
            scope: "openid profile email offline_access",
          },
        });
      } catch (e: any) {
        console.log(e.message);
      }
    };
  
    getUserMetadata();
  })

  return <Component />;
};

export default ProtectedRoute;
